import { Mode } from "@/types/util";
import { useQuery } from "@tanstack/react-query";
import Cookies from "js-cookie";
import useJwt from "./session";
import { vercelEnv } from "./environment";

const isProduction = vercelEnv === "production";

const EMAIL_API_URL =
    (process.env.NEXT_PUBLIC_BASE_URL_HEROKU ||
        (isProduction ? `https://api-prod.blockbeat.io` : `https://api-dev.blockbeat.io`)) + "/email";

async function getAccessListContacts(jwt): Promise<Array<any>> {
    const res = await fetch(`${EMAIL_API_URL}/access-list-contacts`, {
        headers: { jwt },
    });

    if (!res.ok) {
        throw new Error("Failed to fetch data");
    }

    return res.json();
}

export const useAccessListContacts = () => {
    const jwt = useJwt();

    const queryKey = ["accessListContacts"];
    return useQuery({
        queryKey,
        queryFn: () => getAccessListContacts(jwt),
        enabled: !!jwt,
    });
};

async function getVerifiedAccessListContacts(jwt): Promise<Array<any>> {
    const res = await fetch(`${EMAIL_API_URL}/verified-access-list-contacts`, {
        headers: { jwt },
    });
    if (!res.ok) {
        throw new Error("Failed to fetch data");
    }
    return res.json();
}

export const useVerifiedAccessListContacts = () => {
    const jwt = useJwt();

    const queryKey = ["verifiedAccessListContacts"];
    return useQuery({
        queryKey,
        queryFn: () => getVerifiedAccessListContacts(jwt),
        enabled: !!jwt,
    });
};

async function getReferredUsersList(jwt): Promise<Array<any>> {
    const res = await fetch(`${EMAIL_API_URL}/referred-users-list`, {
        headers: { jwt },
    });
    if (!res.ok) {
        throw new Error("Failed to fetch data");
    }
    return res.json();
}

export const useReferredUsersList = () => {
    const jwt = useJwt();

    const queryKey = ["referredUsersList"];
    return useQuery({
        queryKey,
        queryFn: () => getReferredUsersList(jwt),
        enabled: !!jwt,
    });
};

export const viewOnDesktopEmail = async ({
    to,
    href,
    origin,
}: {
    to: string;
    href: string;
    origin: string;
}) => {
    const res = await fetch(`${EMAIL_API_URL}/send-view-on-desktop`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify({ to, href, origin }),
    });

    if (!res.ok) {
        throw new Error("Failed to post data");
    }

    return await res.json();
};

export const sendSuccessfulProSubscriptionEmail = async (mode: Mode, origin: string) => {
    const jwt = Cookies.get("stytch_session_jwt") as string;

    const res = await fetch(`${EMAIL_API_URL}/send-pro-subscription-email`, {
        method: "POST",
        headers: { jwt, "Content-Type": "application/json" },
        body: JSON.stringify({ mode, origin }),
    });

    if (!res.ok) {
        throw new Error("Failed to send email");
    }

    return await res.json();
};

export const requestBetaAccessCode = async () => {
    const jwt = Cookies.get("stytch_session_jwt") as string;

    const res = await fetch(`${EMAIL_API_URL}/request-beta-access-code`, {
        method: "POST",
        headers: { jwt, "Content-Type": "application/json" },
    });

    if (!res.ok) {
        throw new Error("Failed to post data");
    }

    return await res.json();
};

export const verifyBetaAccessCode = async (code: string) => {
    const jwt = Cookies.get("stytch_session_jwt") as string;

    const res = await fetch(`${EMAIL_API_URL}/verify-beta-access-code`, {
        method: "POST",
        headers: { jwt, "Content-Type": "application/json" },
        body: JSON.stringify({ code }),
    });

    if (!res.ok) {
        throw new Error("Failed to post data");
    }

    return await res.json();
};

export const sendWelcomeEmail = async () => {
    const jwt = Cookies.get("stytch_session_jwt") as string;

    const res = await fetch(`${EMAIL_API_URL}/send-welcome-email`, {
        method: "POST",
        headers: { jwt, "Content-Type": "application/json" },
    });

    if (!res.ok) {
        throw new Error("Failed to send email");
    }

    return await res.json();
};

export const sendSuccessfulPasswordResetEmail = async (origin: string) => {
    const jwt = Cookies.get("stytch_session_jwt") as string;

    const res = await fetch(`${EMAIL_API_URL}/send-successful-password-reset-email`, {
        method: "POST",
        headers: { jwt, "Content-Type": "application/json" },
        body: JSON.stringify({ origin }),
    });

    if (!res.ok) {
        throw new Error("Failed to send email");
    }

    return await res.json();
};

export const verifyAffiliateCode = async (referralCode: string) => {
    const jwt = Cookies.get("stytch_session_jwt") as string;

    const res = await fetch(`${EMAIL_API_URL}/verify-affiliate-code`, {
        method: "POST",
        headers: { jwt, "Content-Type": "application/json" },
        body: JSON.stringify({ referralCode }),
    });

    if (!res.ok) {
        throw new Error("Failed to verify affiliate code");
    }

    return await res.json();
};
