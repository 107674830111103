"use client";

import { useStytchSession } from "@stytch/nextjs";
import Cookies from "js-cookie";
import { useEffect, useState } from "react";

const useJwt = () => {
    const [jwt, setJwt] = useState<string | null>(null);
    const { session } = useStytchSession();

    useEffect(() => {
        const fetchJwt = () => {
            const token = Cookies.get("stytch_session_jwt") as string;
            setJwt(token);
        };

        if (session) {
            fetchJwt();
        } else {
            setJwt(null);
        }
    }, [session]);

    return jwt;
};

export default useJwt;
