"use client";

import { ButtonHTMLAttributes, CSSProperties, DetailedHTMLProps } from "react";

export type IButton = React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
>;

export function Button({
    onClick = console.log,
    disabled = false,
    children,
    className,
    primary = false,
    secondary = false,
    type,
    id,
    style,
}: {
    onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
    disabled?: boolean;
    children?: React.ReactNode;
    className?: string;
    primary?: boolean;
    secondary?: boolean;
    type?: DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>["type"];
    id?: string;
    style?: CSSProperties | undefined;
}) {
    return (
        <button
            onClick={onClick}
            type={type || "button"}
            disabled={disabled}
            className={`${className} btn min-h-8 rounded-lg normal-case ${
                primary ? `bg-[#1B87E5] text-white` : secondary && `bg-gray-700 text-light-blue`
            }`}
            id={id}
            style={style}
        >
            {children}
        </button>
    );
}
