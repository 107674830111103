import { Mode } from "@/types/util";
import { useMutation, useQuery } from "@tanstack/react-query";
import { queryClient } from "./queryclient";
import { sendSuccessfulProSubscriptionEmail } from "./email";
import useJwt from "./session";
import { vercelEnv } from "./environment";

interface PaymentDetails {
    author: string;
    created: string;
    entity: string;
    modified: string;
    paidAt: string;
    payment_id: string;
    subscription_expiration_date: string;
    subscription_type: string;
    user_email: string;
    user_id: string;
    subscriptionId?: string;
}

interface MostRecentPaymentResponse {
    paymentDetails: PaymentDetails;
    subIsActive: boolean;
}

const isProduction = vercelEnv === "production";

export const PAYMENTS_API_URL =
    (process.env.NEXT_PUBLIC_BASE_URL_HEROKU ||
        (isProduction ? `https://api-prod.blockbeat.io` : `https://api-dev.blockbeat.io`)) + "/payments";

const payWithCoupon = async (coupon: string, jwt) => {
    const res = await fetch(`${PAYMENTS_API_URL}/use-coupon`, {
        method: "POST",
        headers: { jwt, "Content-Type": "application/json" },
        body: JSON.stringify({ coupon }),
    });

    if (!res.ok) {
        throw new Error("Failed to make request");
    }

    return await res.json();
};

export const usePayWithCouponMutation = () => {
    const jwt = useJwt();

    return useMutation({
        mutationFn: ({ coupon }: { coupon: string }) => payWithCoupon(coupon, jwt),

        onSuccess: () => {
            queryClient.refetchQueries({ queryKey: ["userSubscription"] });
            sendSuccessfulProSubscriptionEmail(Mode.Coupon, window.location.origin);
        },
    });
};

const createNewPaymentEntry = async (
    payment_id: string,
    subscription_type: Mode,
    subscriptionId: string,
    jwt,
) => {
    const res = await fetch(`${PAYMENTS_API_URL}/create`, {
        method: "POST",
        headers: { jwt, "Content-Type": "application/json" },
        body: JSON.stringify({ payment_id, subscription_type, subscriptionId }),
    });

    if (!res.ok) {
        throw new Error("Failed to make request");
    }

    return await res.json();
};

export const useCreateNewPaymentMutation = () => {
    const jwt = useJwt();

    return useMutation({
        mutationFn: ({
            payment_id,
            subscription_type,
            subscriptionId,
        }: {
            payment_id: string;
            subscription_type: Mode;
            subscriptionId: string;
        }) => createNewPaymentEntry(payment_id, subscription_type, subscriptionId, jwt),
        onMutate: async () => {
            await queryClient.cancelQueries({ queryKey: ["userSubscription"] });
            const existingSub = queryClient.getQueryData(["userSubscription"]);
            queryClient.setQueryData(["userSubscription"], (old: any) => {
                return {
                    ...old,
                    subIsActive: true,
                };
            });
            return { existingSub };
        },

        onSuccess: (data, variables) => {
            // this is so the key created for when the subscription is created can become unfit for use
            queryClient.invalidateQueries({ queryKey: ["createSubscription"], exact: false });
            queryClient.refetchQueries({ queryKey: ["userSubscription"] });
            sendSuccessfulProSubscriptionEmail(variables.subscription_type, window.location.origin);
        },
    });
};

const updatePaymentEntry = async (payment_id: string, subscriptionId: string, jwt) => {
    const res = await fetch(`${PAYMENTS_API_URL}/update`, {
        method: "POST",
        headers: { jwt, "Content-Type": "application/json" },
        body: JSON.stringify({ payment_id, subscriptionId }),
    });

    if (!res.ok) {
        throw new Error("Failed to make request");
    }

    return await res.json();
};

export const useUpdatePaymentEntryMutation = () => {
    const jwt = useJwt();

    return useMutation({
        mutationFn: ({ payment_id, subscriptionId }: { payment_id: string; subscriptionId: string }) =>
            updatePaymentEntry(payment_id, subscriptionId, jwt),
        onSuccess: () => queryClient.refetchQueries({ queryKey: ["userSubscription"] }),
    });
};

export const retrieveSinglePaymentDetails = async (payment_id: string) => {
    const res = await fetch(`${PAYMENTS_API_URL}/retrieve-payment/${payment_id}`);
    if (!res.ok) {
        throw new Error("Failed to fetch data");
    }

    return res.json();
};

const retrieveAllUserPayments = async (jwt) => {
    const res = await fetch(`${PAYMENTS_API_URL}/retrieve-user-payments`, {
        headers: { jwt },
    });

    if (!res.ok) {
        throw new Error("Failed to make request");
    }

    const data = await res.json();

    return data.payments;
};

export const useRetrieveAllUserPayments = () => {
    const jwt = useJwt();

    const queryKey = ["allUserPayments"];
    return useQuery({
        queryKey,
        queryFn: () => retrieveAllUserPayments(jwt),
        enabled: !!jwt,
    });
};

const retrieveMostRecentPayment = async (jwt) => {
    const res = await fetch(`${PAYMENTS_API_URL}/retrieve-most-recent-payment`, {
        headers: { jwt },
    });

    if (!res.ok) {
        throw new Error("Failed to make request");
    }

    const data = await res.json();
    const { payment, userHasActiveSubscription } = data;
    // for now, make all users access pro
    // replace true with userHasActiveSubscription || false later

    return { paymentDetails: payment, subIsActive: true };
};

export const useUserSubscription = () => {
    const jwt = useJwt();
    const queryKey = ["userSubscription"];

    return useQuery<MostRecentPaymentResponse>({
        queryKey,
        queryFn: () => retrieveMostRecentPayment(jwt),
        enabled: !!jwt,
    });
};
