import { CreatedSubscription, Mode } from "@/types/util";
import { Stripe, loadStripe } from "@stripe/stripe-js";
import { useMutation, useQuery } from "@tanstack/react-query";
import { queryClient } from "./queryclient";
import useJwt from "./session";
import { vercelEnv } from "./environment";

const isProduction = vercelEnv === "production";

export const STRIPE_API_URL =
    (process.env.NEXT_PUBLIC_BASE_URL_HEROKU ||
        (isProduction ? `https://api-prod.blockbeat.io` : `https://api-dev.blockbeat.io`)) + "/stripe";

let stripePromise: Promise<Stripe | null>;

export const getStripe = () => {
    if (!stripePromise) {
        stripePromise = loadStripe(process.env.NEXT_PUBLIC_STRIPE_PUBLISHABLE_KEY!);
    }
    return stripePromise;
};

export const useCreateSubQuery = (mode: Mode) => {
    return useQuery<CreatedSubscription>({
        queryKey: ["createSubscription", mode],
        queryFn: async () => {
            const cachedData: CreatedSubscription | undefined = queryClient.getQueryData([
                "createSubscription",
                mode,
            ]);
            if (cachedData) {
                return cachedData;
            } else
                return {
                    clientSecret: "",
                    message: "",
                    subscriptionId: "",
                };
        },
    });
};

const createSubscription = async (mode: Mode, jwt) => {
    const res = await fetch(`${STRIPE_API_URL}/create-subscription`, {
        method: "POST",
        headers: { jwt, "content-type": "application/json" },
        body: JSON.stringify({ mode }),
    });

    if (!res.ok) {
        throw new Error("Failed to make request");
    }

    return await res.json();
};

export const useCreateSubscriptionMutation = () => {
    const jwt = useJwt();

    return useMutation({
        mutationFn: (mode: Mode) => createSubscription(mode, jwt),
        onSuccess: (data, variables) => {
            queryClient.setQueryData(["createSubscription", variables], {
                clientSecret: data.clientSecret,
                message: data.message,
                subscriptionId: data.subscriptionId,
            });
        },
        onError: (error) => {
            console.error("Failed to create subscription:", error);
        },
    });
};

const fetchCustomerPaymentMethods = async (jwt: any, starting_after?: string) => {
    const res = await fetch(`${STRIPE_API_URL}/fetch-customer`, {
        method: "POST",
        headers: { jwt, "content-type": "application/json" },
        body: JSON.stringify({ starting_after }),
    });

    if (!res.ok) {
        throw new Error("Failed to make request");
    }

    const data = await res.json();

    const methods = data.methods;

    return methods;
};

export const useCustomerPaymentMethods = (starting_after?: string) => {
    const jwt = useJwt();

    const queryKey = ["customerPaymentMethods"];
    return useQuery({
        queryKey,
        queryFn: () => fetchCustomerPaymentMethods(jwt, starting_after),
        enabled: !!jwt,
    });
};

const cancelSubscription = async (id: string, jwt) => {
    const res = await fetch(`${STRIPE_API_URL}/cancel-subscription`, {
        method: "POST",
        headers: { jwt, "content-type": "application/json" },
        body: JSON.stringify({ id }),
    });

    if (!res.ok) {
        throw new Error("Failed to make request");
    }

    return await res.json();
};

export const useCancelSubscriptionMutation = () => {
    const jwt = useJwt();

    return useMutation({
        mutationFn: (id: string) => cancelSubscription(id, jwt),
        onSuccess: () => queryClient.refetchQueries({ queryKey: ["userSubscription"] }),
    });
};

// const addCardToCustomer = async (method_id: string, ) => {
//     const res = await fetch(`${STRIPE_API_URL}/add-method`, {
//         method: "POST",
//         headers: { jwt, "content-type": "application/json" },
//         body: JSON.stringify({ method_id }),
//     });

//     if (!res.ok) {
//         throw new Error("Failed to make request");
//     }

//     return await res.json();
// };

const removeCardFromCustomer = async (method_id: string, jwt) => {
    const res = await fetch(`${STRIPE_API_URL}/detach-method`, {
        method: "POST",
        headers: { jwt, "content-type": "application/json" },
        body: JSON.stringify({ method_id }),
    });

    if (!res.ok) {
        throw new Error("Failed to make request");
    }

    return await res.json();
};

export const useRemoveCardMutation = () => {
    const jwt = useJwt();

    return useMutation({
        mutationFn: ({ method_id }: { method_id: string }) => removeCardFromCustomer(method_id, jwt),

        // mutationFn: ({ method_id }: { method_id: string }) => removeCardFromCustomer(method_id),
        onMutate: async (variables) => {
            await queryClient.cancelQueries({ queryKey: ["customerPaymentMethods"] });

            const previousPaymentMethods = queryClient.getQueryData(["customerPaymentMethods"]);

            queryClient.setQueryData(["customerPaymentMethods"], (old: any) => {
                return old.filter((method: any) => method.id !== variables.method_id);
            });

            return { previousPaymentMethods };
        },
        onSuccess: () => {
            queryClient.refetchQueries({ queryKey: ["customerPaymentMethods"] });
        },
        onError: (error, variables, context) => {
            queryClient.setQueryData(["customerPaymentMethods"], context?.previousPaymentMethods);
        },
    });
};
