import { QueryClient } from "@tanstack/react-query";

export const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
            refetchOnMount: true,
            retry: 3,
            retryOnMount: false,
            staleTime: 1000 * 60,
        },
    },
});
